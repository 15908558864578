import React from "react";
import { Stack, Image, Box, Heading, Text } from "@chakra-ui/react";

interface ContentBoxProps {
    id: number;
    src: any;
    alt: string;
    title: string;
    text: string;
  }

const ContentBox: React.FC<ContentBoxProps> = ({  id, src, alt, title, text }: any) => (
  <Box key={id} pr={{ base: 0, md: 20 }}>
    <Stack
      display="flex"
      flexFlow="row"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Image src={src} alt={alt} w={6} h={6} />
      <Heading
        as="h2"
        pl={2}
        fontWeight={400}
        fontSize="xs !important"
        h="49px"
        display="flex"
        alignItems="center"
        borderLeft="1px solid #ddd"
      >
        {title}
      </Heading>
    </Stack>
    <Text color="#4A5568" fontSize="sm" py={8}>
      {text}
    </Text>
  </Box>
);

export default ContentBox;
