// extracted by mini-css-extract-plugin
export var headingLarge2xl = "hero-container-module--headingLarge2xl--0dc75";
export var headingLarge3xl = "hero-container-module--headingLarge3xl--e9604";
export var heroContent = "hero-container-module--heroContent--628d2";
export var heroText = "hero-container-module--heroText--b1f54";
export var herobackgroundIcon = "hero-container-module--herobackgroundIcon--bcb85";
export var herosection = "hero-container-module--herosection--7e63f";
export var overlay = "hero-container-module--overlay--48ee1";
export var remixIconslinesystemarrow = "hero-container-module--remixIconslinesystemarrow--95b6a";
export var sectionText = "hero-container-module--sectionText--5f84c";
export var sectionicon = "hero-container-module--sectionicon--b219d";