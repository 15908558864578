// extracted by mini-css-extract-plugin
export var aboutcolumn = "about-us-module--aboutcolumn--411fa";
export var aboutcolumn1 = "about-us-module--aboutcolumn1--95c74";
export var aboutcolumn2 = "about-us-module--aboutcolumn2--7b233";
export var aboutus = "about-us-module--aboutus--38aab";
export var aboutussection = "about-us-module--aboutussection--738d5";
export var aboutusslot = "about-us-module--aboutusslot--c6b70";
export var feature = "about-us-module--feature--c3700";
export var howtoreach = "about-us-module--howtoreach--54175";
export var howtorow = "about-us-module--howtorow--5fbab";
export var howtorow1 = "about-us-module--howtorow1--aa5ea";
export var p = "about-us-module--p--9b713";
export var p2 = "about-us-module--p2--b2893";
export var shipIcon = "about-us-module--shipIcon--c426f";
export var shipping = "about-us-module--shipping--1df62";
export var shippingDelivery = "about-us-module--shippingDelivery--ea381";
export var split = "about-us-module--split--eee24";
export var weShipYour = "about-us-module--weShipYour--4bf21";