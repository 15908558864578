type ContentBoxType = {
    id: number;
    src: any;     
    alt: string;  
    title: string;
    text: string; 
  };
  
  const contentBox: ContentBoxType[] = [
    {
      id: 1,
      src: "/ship.svg",
      alt: "ship",
      title: "Shipping & Delivery",
      text: "We ship your order out within 48 hours, and often ship the same day. Delivery time depends on your location, but orders are usually delivered within five to seven business days. We at Doctor Medica even offer free shipping, right to your office for your convenience. We ship temperature-sensitive products in special packages to ensure your medical supplies arrive safely. You can always track the progress of your package with the tracking number we provide and our customer service team is available to answer any questions you may have."
    },
    {
      id: 2,
      src: "/headset1.svg",
      alt: "customer service",
      title: "Customer Service",
      text: "Although DoctorMedica.co stocks a wide variety of cosmetic and orthopedic injectables, as well as creams, peels, and other aesthetic products, there may be times you cannot find a specific product you are looking for. If that is the case, please contact our customer service team and ask for a personalized quote. We are here to help you find the medical supplies you need to provide your patients with the best quality service at the best price."
    },
    {
      id: 3,
      src: "/mail9.svg",
      alt: "contact",
      title: "How To Reach Us",
      text: "You can reach Doctor Medica by phone, fax, or email. Our dedicated customer service team is available to answer your questions or receive your comments during business hours Monday to Friday. We look forward to hearing from you and providing the supplies you need for your medical or aesthetic practice. Contact us today to place your order for the best price, guaranteed."
    }
  ];
  
  // Export the content boxes array if needed elsewhere
  export default contentBox;
  