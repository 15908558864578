import * as React from 'react';
import * as styles from './hero-container.module.css';

import CSS, { Property } from 'csstype';
import { FunctionComponent, useMemo } from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Text } from '@chakra-ui/react';

type HeroContainerType = {
	heroImage?: string;
	heroSubTitle: string;
	heroTitle?: string;
	sectionDescription?: string;
	arrowImage?: string;
	url?: string;

	/** Style props */
	heroBackgroundPadding?: Property.Padding;
};

const HeroContainer: FunctionComponent<HeroContainerType> = ({
	heroImage,
	heroSubTitle,
	heroTitle,
	sectionDescription,
	arrowImage,
	heroBackgroundPadding,
	url,
}) => {
	const heroSectionStyle: CSS.Properties = useMemo(() => {
		return {
			padding: heroBackgroundPadding,
		};
	}, [heroBackgroundPadding]);
	const path = typeof window !== 'undefined' ? window.location.pathname : '';

	return (
		<div
			className={styles.herosection}
			style={heroSectionStyle}>
			<img
				className={styles.herobackgroundIcon}
				alt=''
				src={heroImage}
			/>
			<div className={styles.overlay} />
			<div className={styles.heroContent}>
				<Text className={styles.headingLarge2xl}>{heroSubTitle}</Text>
				<div className={styles.heroText}>
					<h1 className={styles.headingLarge3xl}>{heroTitle}</h1>
					<Text className={styles.sectionText}>{sectionDescription}</Text>
				</div>
			</div>
			<div className={styles.sectionicon}>
				<AnchorLink to={path + '#anchorlink'}>
					<img
						className={styles.remixIconslinesystemarrow}
						alt='Hero Image'
						src={arrowImage}
					/>
				</AnchorLink>
			</div>
		</div>
	);
};

export default HeroContainer;
